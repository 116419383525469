@import '~antd/dist/antd.css';

[data-tour-elem='controls'] {
  margin-top: 30px;
  justify-content: center;
}

input.ant-select-selection-search-input {
  box-shadow: none !important;
}

*:not(i) {
  font-family: 'Inter', sans-serif !important;
}

.ant-tree-select {
  border-radius: 0.375rem !important;
}

.ant-tree-select.ant-select-focused .ant-select-selector {
  border: 2px solid #3b82f6 !important;
}

.ant-tree-select .ant-select-selector {
  background-color: none;
  border: 1px solid #d1d5db !important;
  border-radius: 0.375rem !important;
  min-height: 38px !important;
}

.ant-tree-select .ant-select-selection-item {
  margin-top: 3px;
  padding: 0 6px !important;
  border-radius: 5px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;